import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionAboutUs.module.css';
import tyreChange from './images/tyreChange.png';
import { string } from 'prop-types';
import { FaArrowRight } from 'react-icons/fa';
import logoImage from '../../assets/logo032024.png';
import border from '../../assets/border.png';
import NamedLink from '../NamedLink/NamedLink';
import { AiFillStar } from 'react-icons/ai';
import { StaticPage } from '../../containers';
const SectionAboutUs = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'About Us',
        description: 'About Us',
        name: 'About Us',
      }}
      description={'automarketplace.com'}
      isStaticPage={true}
    >
      <div className={classes}>
        <div className={css.heroContainer}>
          <div className={css.contentWrapper}>
            {/* <img src={logoImage} className={css.logo} alt="AutoMarketplace" /> */}

            {/* <div className={css.borderContainer}>
              <h1 className={css.sectionTitle}>About Us</h1>
              <img src={border} className={css.border} alt="AutoMarketplace" />
            </div> */}

            <div className={css.content}>
              <h1 className={css.sectionTitle}>About Us</h1>
              <p className={css.paragraph}>
                Before starting AutoMarketplace, we operated a for-hire vehicle (“TLC”) fleet in New
                York City.
              </p>
              <p className={css.paragraph}>
                We know the challenges and opportunities associated with buying, financing,
                insuring, renting, owning, maintaining & fixing vehicles in NYC.
              </p>
            </div>
            <div className={css.content}>
              <h1 className={css.sectionTitle}>NYC’s Best</h1>
              <p className={css.paragraph}>
                Google, Yelp and other popular online ratings are helpful, but many shops and
                vendors manipulate their ratings.
                <b> Are there really that many 4.0+ places to do business?</b> Remember, many
                reviews are user-generated and while useful, such a rating system can be abused.{' '}
                <br />
                <br />
                To help create a better system, AutoMarketplace created its own rating methodology
                based on private, verified user feedback and our own experiences.
              </p>
              <div className={css.ratingSection}>
                <AiFillStar className={css.star} />

                <p className={css.ratingText}>
                  3.5 = Good <br />
                  4.0 = Very Good <br />
                  4.5 = Excellent <br />
                  4.6+ = Best in NYC
                </p>
              </div>
            </div>
            <div className={css.content}>
              <h1 className={css.sectionTitle}>Trusted Brand In NYC TLC</h1>
              <p className={css.paragraph}>
                AutoMarketplace is the largest content producer and distribution platform focused on
                the NYC for-hire transportation (“TLC”) industry.
                <br />
                <br />
                Our content, is engaged by NYC drivers, fleets, insurance brokers, rideshare
                companies, bases, taxi medallion owners, driver advocates, policymakers and many
                others.
              </p>
              <div className={css.arrowContainer}>
                <p className={css.newsletter}>
                  <a href="https://automarketplace.substack.com/" target="__blank">
                    Newsletter
                  </a>
                </p>
                <p className={css.newsletter}>
                  <a
                    href="https://www.youtube.com/channel/UCBLspAbS3IQu-w9QaEaVyQg"
                    target="__blank"
                  >
                    YouTube
                  </a>
                </p>
              </div>
            </div>
            <div className={css.content}>
              <h1 className={css.sectionTitle}>Contact Us</h1>
              {/* <p className={css.emailMobile}>
                <span className={css.email}>info@automarketplace.com</span> for any questions
              </p> */}
              <p className={css.emailDesktop}>
                If you have any questions, please email us at{' '}
                <span className={css.email}>info@automarketplace.com</span>
              </p>
            </div>
            <div className={css.content}>
              <h1 className={css.sectionTitle}>Video</h1>
              <div className={css.videoContainer}>
                <p className={css.paragraph} style={{ textAlign: 'center' }}>
                  <i>
                    <b>coming soon...</b>
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StaticPage>
  );
};

SectionAboutUs.defaultProps = { rootClassName: null, className: null };

SectionAboutUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAboutUs;
