import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1>Legal</h1>
      <h2>
        Terms of Service <span className={css.text}>(last updated 12.07.2024)</span>
      </h2>
      <p>
        Welcome to AutoMarketplace.com, the website and online service of Auto Marketplace LLC
        ("Company " "we, " or "us "). This page explains the terms by which you may use our service.
        By accessing or using the AutoMarketplace service, website, related content (e.g.,
        newsletter, YouTube channel, other social media) and software provided through or in
        connection with the service ("Service"), you signify that you have read, understood, and
        agree to be bound by this Terms of Service Agreement ("Agreement "), whether or not you are
        a registered user of our Service.
      </p>
      <h2>Use of Our Service</h2>
      <p>
        AutoMarketplace provides a place for businesses and consumers to learn about, contact and/or
        message different vendors who service the New York City (“NYC”) for-hire transportation
        industry, often known as the NYC TLC (“TLC”) industry. We also provide a place for
        businesses and consumers to learn about, contact and/or message different vendors who
        service broader automotive and transportation markets.
      </p>
      <p>
        <b>
          All listings and ratings on AutoMarketplace.com are created and maintained by
          AutoMarketplace. Only AutoMarketplace can create and maintain listings, ratings, and all
          other attributes of any listing on AutoMarketplace.com.
        </b>
      </p>
      <p>
        <b>
          For the avoidance of doubt, if you message and/or schedule an appointment on
          AutoMarketplace.com you are communicating with us, who in turn communicates with the
          listed vendor / party and/or another third party. Any replies and/or appointment
          confirmations from AutoMarketplace.com come from us, not directly from the listed vendor /
          party. If appropriate, we might also give your contact details to a vendor you have
          messaged or scheduled an appointment with on AutoMarketplace.com.
        </b>
      </p>
      <p>
        These vendors include, but are not limited to, insurance brokers, service providers focused
        on NYC Taxi & Limousine Commission (TLC) and Department of Motor Vehicle (DMV)
        administration, companies and individuals who lease or rent out NYC for-hire vehicles (FHVs)
        and/or standalone NYC FHV licenses, companies and individuals who lease, manage and/or rent
        NYC yellow cabs (taxis), companies and individuals who sell and/or purchase NYC yellow cab
        (taxi) medallions, companies and individuals who provide transportation services.
      </p>
      <p>
        AutoMarketplace grants you permission to use the Service as set forth in this Agreement,
        provided that: (i) you will not copy, distribute, or disclose any part of the Service in any
        medium; (ii) you will not alter or modify any part of the Service other than as may be
        reasonably necessary to use the Service for its intended purpose; and (iii) you will
        otherwise comply with the terms and conditions of this Agreement.
      </p>
      <p>
        You do not have to register in order to visit AutoMarketplace. To access certain features of
        the Service, though, including messaging or scheduling an appointment with a listed vendor,
        you will need to register with AutoMarketplace and create an account. Your account gives you
        access to the services and functionality that we may establish and maintain from time to
        time and in our sole discretion.
      </p>
      <p>
        You may never use another Member's account without permission. When creating your account,
        you must provide accurate and complete information. You are solely responsible for the
        activity that occurs on your account, and you must keep your account password secure. You
        must notify AutoMarketplace
        <br />(<a href="mailto:support@automarketplace.com"> support@automarketplace.com</a>)
        immediately of any breach of security or unauthorized use of your account. Although
        AutoMarketplace will not be liable for your losses caused by any unauthorized use of your
        account, you shall be liable for the losses of AutoMarketplace or others due to such
        unauthorized use.
      </p>
      <p>
        You may use your Profile settings and Account settings to control your Member Profile and
        how other Members communicate with you. By providing AutoMarketplace with your email address
        you consent to our using the email address to send you Service-related notices, including
        any notices required by law, in lieu of communication by postal mail. You may opt out or
        change the frequency of many Service-related communications by contacting
        <b>
          <a href="mailto:support@automarketplace.com"> support@automarketplace.com</a>
        </b>
        .
      </p>
      <p>
        We may also use your email address to send you other messages, including changes to features
        of the Service and special offers. If you do not want to receive such email messages, you
        may opt out by contacting{' '}
        <b>
          <a href="mailto:support@automarketplace.com">support@automarketplace.com</a>
        </b>
        . Opting out may prevent you from receiving email messages regarding updates, improvements,
        or offers.
      </p>
      <p>
        You agree not to use or launch any automated system, including without limitation, "robots,
        " "spiders, " "offline readers, " etc., that accesses the Service in a manner that sends
        more request messages to the AutoMarketplace servers than a human can reasonably produce in
        the same period of time by using a conventional online web browser. You agree not to collect
        or harvest any personally identifiable information, including account names, from the
        Service nor to use the communication systems provided by the Service for any commercial
        solicitation purposes.
      </p>
      <p>
        AutoMarketplace may permanently or temporarily terminate, suspend, or otherwise refuse to
        permit your access to the Service without notice and liability, if, in AutoMarketplace's
        sole determination, you violate any of the Agreement, including the following prohibited
        actions: (i) attempting to interfere with, compromise the system integrity or security or
        decipher any transmissions to or from the servers running the Service; (ii) taking any
        action that imposes, or may impose at our sole discretion an unreasonable or
        disproportionately large load on our infrastructure; (iii) uploading invalid data, viruses,
        worms, or other software agents through the Service; (iv) impersonating another person or
        otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding
        or attempting to hide your identity; (v) interfering with the proper working of the Service;
        or, (vi) bypassing the measures we may use to prevent or restrict access to the Service.
        Upon termination for any reason, you continue to be bound by this Agreement.
      </p>
      <h2>User Content</h2>
      <p>
        Some areas of the Service may allow Users to post feedback, comments, questions, and other
        information (“User Content”). Your User Content may include facts, ratings, reviews, or
        other discrete pieces of information relating to your experiences with or opinions relating
        to your experience with vendors listed on AutoMarketplace.com. You are solely responsible
        for your User Content that you upload, publish, display, link to or otherwise make available
        (hereinafter, “post”) on the Service, and you agree that we are only acting as a passive
        conduit for your online distribution and publication of your User Content.
      </p>
      <p>
        You agree not to post User Content that: (i) may create a risk of harm, loss, physical or
        mental injury, emotional distress, death, disability, disfigurement, or physical or mental
        illness to you, to any other person, or to any animal; (ii) may create a risk of any other
        loss or damage to any person or property; (iii) may constitute or contribute to a crime or
        tort; (iv) contains any information or content that we deem to be unlawful, harmful,
        abusive, racially or ethnically offensive, defamatory, infringing, invasive of personal
        privacy or publicity rights, harassing, humiliating to other people (publicly or otherwise),
        libelous, threatening, or otherwise objectionable; (v) contains any information or content
        that is illegal; (vi) contains any information or content that you do not have a right to
        make available under any law or under contractual or fiduciary relationships; or (vii)
        contains any information or content that you know is not correct and current.
      </p>
      <p>
        You agree that any User Content that you post does not and will not violate third-party
        rights of any kind, including without limitation any Intellectual Property Rights (as
        defined below), rights of publicity and privacy. AutoMarketplace reserves the right, but is
        not obligated, to reject and/or remove any User Content that AutoMarketplace believes, in
        its sole discretion, violates these provisions. You understand that publishing your User
        Content on the Service is not a substitute for registering it with the U.S. Copyright, the
        Writer's Guild of America, or any other rights organization.
      </p>
      <p>
        For the purposes of this Agreement, “Intellectual Property Rights” means all patent rights,
        copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade
        dress and service mark rights, goodwill, trade secret rights and other intellectual property
        rights as may now exist or hereafter come into existence, and all applications therefore and
        registrations, renewals and extensions thereof, under the laws of any state, country,
        territory or other jurisdiction.
      </p>
      <p>
        AutoMarketplace takes no responsibility and assumes no liability for any User Content that
        you or any other Users or third parties post or send over the Service. You understand and
        agree that any loss or damage of any kind that occurs as a result of the use of any User
        Content that you send, upload, download, stream, post, transmit, display, or otherwise make
        available or access through your use of the Service, is solely your responsibility.
        AutoMarketplace is not responsible for any public display or misuse of your User Content.
        You understand and acknowledge that you may be exposed to User Content that is inaccurate,
        offensive, indecent, or objectionable, and you agree that AutoMarketplace shall not be
        liable for any damages you allege to incur as a result of such User Content.
      </p>
      <p>
        You also agree that AutoMarketplace reviews cannot be disputed by Users or others who choose
        to interact with our Service. If you have feedback about any of our reviews please email
        <a href="mailto:support@automarketplace.com"> support@automarketplace.com</a>.
      </p>
      <p>
        You are solely responsible for your interactions with other AutoMarketplace Users. We
        reserve the right, but have no obligation, to monitor disputes between you and other Users.
      </p>
      <h2>License Grant</h2>
      <p>
        By posting any User Content on the Service, you expressly grant, and you represent and
        warrant that you have a right to grant, to AutoMarketplace a royalty-free, sublicensable,
        transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce,
        modify, publish, list information regarding, edit, translate, distribute, publicly perform,
        publicly display, and make derivative works of all such User Content and your name, voice,
        and/or likeness as contained in your User Content, in whole or in part, and in any form,
        media or technology, whether now known or hereafter developed for use in connection with the
        Service. Without limiting the foregoing, you further grant to AutoMarketplace the right to
        use, reproduce, distribute, and license to others your data in any non-personally
        identifiable manner, in any manner of media whatsoever.
      </p>
      <p>
        Subject to the terms and conditions of this Agreement, you are hereby granted a
        non-exclusive, limited, personal license to use the Service. AutoMarketplace reserves all
        rights not expressly granted herein in the Service and the AutoMarketplace Content (as
        defined below). AutoMarketplace may terminate this license at any time for any reason or no
        reason.
      </p>
      <h2>Our Proprietary Rights</h2>
      <p>
        Except for your User Content, the Service and all materials therein or transferred thereby,
        including, without limitation, software, images, text, graphics, illustrations, logos,
        patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and User
        Content (the " AutoMarketplace Content "), and all Intellectual Property Rights related
        thereto, are the exclusive property of AutoMarketplace and its licensors. Except as
        explicitly provided herein, nothing in this Agreement shall be deemed to create a license in
        or under any such Intellectual Property Rights, and you agree not to sell, license, rent,
        modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish,
        adapt, edit or create derivative works from any materials or content accessible on the
        Service. Use of the AutoMarketplace Content or materials on the Service for any purpose not
        expressly permitted by this Agreement is strictly prohibited.
      </p>
      <p>
        You may choose to, or we may invite you to submit comments or ideas about the Service,
        including without limitation about how to improve the Service or our products (“Ideas”). By
        submitting any Idea, you agree that your disclosure is gratuitous, unsolicited and without
        restriction and will not place AutoMarketplace under any fiduciary or other obligation, that
        we are free to disclose the Ideas on a non-confidential basis to anyone or otherwise use the
        Ideas without any additional compensation to you. You acknowledge that, by acceptance of
        your submission, AutoMarketplace does not waive any rights to use similar or related ideas
        previously known to AutoMarketplace, or developed by its employees, or obtained from sources
        other than you.
      </p>
      <h2>Eligibility</h2>
      <p>
        This Service is intended solely for Users who are thirteen (13) years of age or older, and
        any registration, use or access to the Service by anyone under 13 is unauthorized,
        unlicensed, and in violation of this Agreement. AutoMarketplace may terminate your account,
        delete any content or information that you have posted on the Service, and/or prohibit you
        from using or accessing the Service (or any portion, aspect or feature of the Service) for
        any reason, at any time in its sole discretion, with or without notice, including without
        limitation if it believes that you are under 13. If you are under 18 years of age you may
        use the Service only if you either are an emancipated minor, or possess legal parental or
        guardian consent, and are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth in this Agreement, and
        to abide by and comply with this Agreement.
      </p>
      <h2>Privacy</h2>
      <p>
        We care about the privacy of our Users. Click here to view our Privacy Policy. By using the
        Service, you are consenting to have your personal data transferred to and processed in the
        United States.
      </p>
      <h2>Security</h2>
      <p>
        We have implemented commercially reasonable technical and organizational measures designed
        to secure your personal information from accidental loss and from unauthorized access, use,
        alteration or disclosure. However, we cannot guarantee that unauthorized third parties will
        never be able to defeat those measures or use your personal information for improper
        purposes. You acknowledge that you provide your personal information at your own risk.
      </p>
      <h2>DMCA Notice</h2>
      <p>
        If you believe that your copyrighted work has been copied in a way that constitutes
        copyright infringement and is accessible via the Service, please notify AutoMarketplace's
        copyright agent (
        <a href="mailto:copyright@automarketplace.com">copyright@automarketplace.com</a>), as set
        forth in the Digital Millennium Copyright Act of 1998 (" DMCA "). For your complaint to be
        valid under the DMCA, you must provide the following information in writing:
      </p>
      <ul className={css.ul}>
        <li>
          An electronic or physical signature of a person authorized to act on behalf of the
          copyright owner;
        </li>
        <li>Identification of the copyrighted work that you claim has been infringed</li>
        <li>
          Identification of the material that is claimed to be infringing and where it is located on
          the Service;
        </li>
        <li>
          Information reasonably sufficient to permit AutoMarketplace to contact you, such as your
          address, telephone number, and, e-mail address;
        </li>
        <li>
          A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorized by the copyright owner, its agent, or law; and
        </li>
        <li>
          A statement, made under penalty of perjury, that the above information is accurate, and
          that you are the copyright owner or are authorized to act on behalf of the owner.
        </li>
      </ul>
      <p>The above information must be submitted to the following DMCA Agent:</p>
      <p>
        Name:
        <br />
        AutoMarketplace DMCA
      </p>
      <p>
        Attn:
        <br />
        DMCA Notice
      </p>
      <p>
        Company:
        <br />
        AUTO MARKETPLACE LLC
      </p>
      <p>
        Address:
        <br />
        REGISTERED AGENTS INC.
        <br />
        418 BROADWAY STE R, ALBANY, NY, UNITED STATES, 12207
      </p>
      <p>
        Email:
        <br />
        <a href="mailto:copyright@automarketplace.com">copyright@automarketplace.com</a>
      </p>
      <p>
        UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY
        BE SUBJECT TO CRIMINAL PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY
        DAMAGES, COURT COSTS, AND ATTORNEYS' FEES.
      </p>
      <p>
        Please note that this procedure is exclusively for notifying AutoMarketplace and its
        affiliates that your copyrighted material has been infringed. The preceding requirements are
        intended to comply with AutoMarketplace's rights and obligations under the DMCA, including
        17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact an
        attorney regarding your rights and obligations under the DMCA and other applicable laws.
      </p>
      <p>
        In accordance with the DMCA and other applicable law, AutoMarketplace has adopted a policy
        of terminating, in appropriate circumstances and at AutoMarketplace's sole discretion,
        members who are deemed to be repeat infringers. AutoMarketplace may also at its sole
        discretion limit access to the Service and/or terminate the accounts of any Users who
        infringe any intellectual property rights of others, whether or not there is any repeat
        infringement.
      </p>
      <h2>Additional Representations and Warranties</h2>
      <p>
        You shall be solely responsible for your own User Content and the consequences of posting or
        publishing it. In connection with User Content, you affirm, represent and warrant, in
        addition to the other representations and warranties in this Agreement, the following:
      </p>
      <p>
        a. You are at least 18 years of age, or if you are under 18 years of age you are either an
        emancipated minor, or possess legal parental or guardian consent, and are fully able and
        competent to enter into the terms, conditions, obligations, affirmations, representations,
        and warranties set forth in this Agreement, and to abide by and comply with this Agreement.
      </p>
      <p>
        b. You have the written consent of each and every identifiable natural person in the User
        Content to use such person's name or likeness in the manner contemplated by the Service and
        this Agreement, and each such person has released you from any liability that may arise in
        relation to such use.
      </p>
      <p>
        c. Your User Content and AutoMarketplace's use thereof as contemplated by this Agreement and
        the Service will not infringe any rights of any third party, including but not limited to
        any Intellectual Property Rights, privacy rights and rights of publicity.
      </p>
      <h2>Third-Party Websites, Advertisers or Services</h2>
      <p>
        AutoMarketplace may contain links to third-party websites, advertisers, or services that are
        not owned or controlled by AutoMarketplace. AutoMarketplace has no control over, and assumes
        no responsibility for, the content, privacy policies, or practices of any third party
        websites or services. If you access a third party website from AutoMarketplace, you do so at
        your own risk, and you understand that this Agreement and AutoMarketplace's Privacy
        Policy do not apply to your use of such sites. You expressly relieve AutoMarketplace from
        any and all liability arising from your use of any third-party website or services or third
        party owned content. Additionally, your dealings with or participation in promotions of
        advertisers found on AutoMarketplace, including payment and delivery of goods, and any other
        terms (such as warranties) are solely between you and such advertisers. You agree that
        AutoMarketplace shall not be responsible for any loss or damage of any sort relating to your
        dealings with such advertisers.
      </p>
      <p>
        We encourage you to be aware of when you leave the Service, and to read the terms and
        conditions and privacy policy of any third-party website or service that you visit.
      </p>
      <h2>Indemnity</h2>
      <p>
        You agree to defend, indemnify and hold harmless AutoMarketplace and its subsidiaries,
        agents, managers, and other affiliated companies, and their employees, contractors, agents,
        officers and directors, from and against any and all claims, damages, obligations, losses,
        liabilities, costs or debt, and expenses (including but not limited to attorney's fees)
        arising from: (i) your use of and access to the Service, including any data or work
        transmitted or received by you; (ii) your violation of any term of this Agreement, including
        without limitation, your breach of any of the representations and warranties above; (iii)
        your violation of any third-party right, including without limitation any right of privacy,
        publicity rights or Intellectual Property Rights; (iv) your violation of any law, rule or
        regulation of the United States or any other country; (v) any claim or damages that arise as
        a result of any of your User Content or any that are submitted via your account; or (vi) any
        other party's access and use of the Service with your unique username, password or other
        appropriate security code.
      </p>
      <h2>No Warranty</h2>
      <p>
        THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. USE OF THE SERVICE IS AT
        YOUR OWN RISK. THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, AUTOMARKETPLACE,
        ITS SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
        CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE AT
        ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
        CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT
        DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN
        RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
        DATA THAT RESULTS FROM SUCH DOWNLOAD.
      </p>
      <p>
        AUTOMARKETPLACE DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
        PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE AUTOMARKETPLACE
        SERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE, OR FEATURED IN ANY BANNER OR OTHER
        ADVERTISING, AND AUTOMARKETPLACE WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY
        TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL AUTOMARKETPLACE, ITS
        AFFILIATES, DIRECTORS, EMPLOYEES OR ITS LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT,
        PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
        LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT
        RESULT FROM THE USE OF, OR INABILITY TO USE, THIS SERVICE. UNDER NO CIRCUMSTANCES WILL
        AUTOMARKETPLACE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING,
        TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT OR THE
        INFORMATION CONTAINED THEREIN.
      </p>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AUTOMARKETPLACE ASSUMES NO LIABILITY OR
        RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL
        INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
        ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
        TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
        TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN
        ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
        EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER
        CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT
        SHALL AUTOMARKETPLACE, ITS AFFILIATES, DIRECTORS, EMPLOYEES, OR LICENSORS BE LIABLE TO YOU
        FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT
        EXCEEDING THE AMOUNT YOU PAID TO AUTOMARKETPLACE HEREUNDER.
      </p>
      <p>
        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF AUTOMARKETPLACE
        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY
        SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
      </p>
      <p>
        The Service is controlled and operated from its facilities in the United States.
        AutoMarketplace makes no representations that the Service is appropriate or available for
        use in other locations. Those who access or use the Service from other jurisdictions do so
        at their own volition and are entirely responsible for compliance with local law, including
        but not limited to export and import regulations. Unless otherwise explicitly stated, all
        materials found on the Service are solely directed to individuals, companies, or other
        entities located in the U.S.
      </p>
      <h2>Assignment</h2>
      <p>
        This Agreement, and any rights and licenses granted hereunder, may not be transferred or
        assigned by you, but may be assigned by AutoMarketplace without restriction.
      </p>
      <p>General</p>
      <p>
        A. Governing Law. You agree that: (i) the Service shall be deemed solely based in New York;
        and (ii) the Service shall be deemed a passive one that does not give rise to personal
        jurisdiction over AutoMarketplace, either specific or general, in jurisdictions other than
        New York. This Agreement shall be governed by the internal substantive laws of the New York
        State, without respect to its conflict of laws principles. Any claim or dispute between you
        and AutoMarketplace that arises in whole or in part from the Service shall be decided
        exclusively by a court of competent jurisdiction located in New York County, New York.
      </p>
      <p>
        B. Notification Procedures. AutoMarketplace may provide notifications, whether such
        notifications are required by law or are for marketing or other business related purposes,
        to you via email notice, written or hard copy notice, or through conspicuous posting of such
        notice on our website, as determined by AutoMarketplace in our sole discretion.
        AutoMarketplace reserves the right to determine the form and means of providing
        notifications to our Users, provided that you may opt out of certain means of notification
        as described in this Agreement.
      </p>
      <p>
        C. Entire Agreement/Severability. This Agreement, together with any other legal notices and
        agreements published by AutoMarketplace via the Service, shall constitute the entire
        agreement between you and AutoMarketplace concerning the Service. If any provision of this
        Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such
        provision shall not affect the validity of the remaining provisions of this Agreement, which
        shall remain in full force and effect.
      </p>
      <p>
        D. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of
        such term or any other term, and AutoMarketplace's failure to assert any right or provision
        under this Agreement shall not constitute a waiver of such right or provision.
      </p>
      <h2>Customer Support Questions or Feedback</h2>
      <p>
        If you have any questions or concerns about this service, you may contact at
        <b>
          <a href="mailto:support@automarketplace.com"> support@automarketplace.com</a>
        </b>
        .
      </p>
      <p>
        Please contact us with any questions regarding this Agreement at{' '}
        <b>
          <a href="mailto:support@automarketplace.com"> support@automarketplace.com</a>
        </b>{' '}
        .
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
