import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, LocationAutocompleteInput, PrimaryButton } from '../../components';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';
import css from './TopbarSearchForm.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import carIcon from './icons/carIcon.svg';
import locationIcon from './icons/locationIcon.svg';
import { compose } from 'redux';

const identity = v => v;

const MAX_IPAD_SCREEN_WIDTH = 1023;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }

  onHandleSubmit(e) {
    document
      .getElementById(this.props.formId)
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            viewport,
            handleSubmit,
          } = formRenderProps;

          const isMobileOrIphone = viewport.width <= MAX_IPAD_SCREEN_WIDTH;
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const rootClass = isMobileOrIphone ? css.mobileInputRoot : desktopInputRootClass;
          const rootClasses = classNames(rootClass, css.root);
          const formClasses = classNames(rootClassName, className);
          const inputClass = isMobileOrIphone ? css.mobileInput : css.desktopInput;
          const classes = classNames(css.input, inputClass);

          return (
            <Form
              className={formClasses}
              id={this.props.formId}
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className={rootClasses}>
                {!isMobileOrIphone ? (
                  <div
                    className={isMobileOrIphone ? css.mobileIcon : css.desktopIcon}
                    onClick={e => (isMobileOrIphone ? this.onHandleSubmit(e) : e.preventDefault())}
                  >
                    <IconHourGlass />
                  </div>
                ) : null}
                <div className={css.searchInputs}>
                  <div className={css.searchBox}>
                    <div className={css.searchBoxRowOne}>
                      <img className={css.icons} src={carIcon} alt="Car" />
                      <Field
                        name="query"
                        format={identity}
                        render={({ input, meta }) => {
                          return (
                            <input
                              className={`${classes} ${css.queryField}`}
                              autoComplete="off"
                              type="search"
                              name="query"
                              ref={this.searchRef}
                              placeholder={
                                isMobileOrIphone
                                  ? intl.formatMessage({ id: 'TopbarSearchForm.mobilePlaceholder' })
                                  : intl.formatMessage({
                                      id: 'TopbarSearchForm.desktopPlaceholder',
                                    })
                              }
                              {...input}
                              onKeyUp={e => {
                                if (e.which === 13) {
                                  this.onHandleSubmit(e);
                                }
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className={css.dividingBorder}></div>
                    <div className={css.searchBoxRowTwo}>
                      <img className={css.icons} src={locationIcon} alt="Location" />
                      <Field
                        name="location"
                        format={identity}
                        render={({ input, meta }) => {
                          const { onChange, ...restInput } = input;
                          const searchOnChange = value => {
                            onChange(value);
                          };

                          const searchInput = { ...restInput, onChange: searchOnChange };

                          return (
                            <LocationAutocompleteInput
                              className={
                                isMobileOrIphone ? css.mobileInputRoot : desktopInputRootClass
                              }
                              iconClassName={isMobileOrIphone ? css.mobileIcon : css.desktopIcon}
                              inputClassName={isMobileOrIphone ? css.mobileInput : css.desktopInput}
                              predictionsClassName={
                                isMobileOrIphone ? css.mobilePredictions : css.desktopPredictions
                              }
                              predictionsAttributionClassName={
                                isMobileOrIphone ? css.mobilePredictionsAttribution : null
                              }
                              placeholder={intl.formatMessage({
                                id: 'TopbarSearchForm.placeholder',
                              })}
                              closeOnBlur={!isMobileOrIphone}
                              inputRef={node => {
                                this.searchInput = node;
                              }}
                              input={searchInput}
                              meta={meta}
                              handlesubmit={this.onHandleSubmit}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className={css.buttonHolder}>
                      <PrimaryButton
                        className={css.searchBtn}
                        onClick={e => this.onHandleSubmit(e)}
                      >
                        <FormattedMessage id="TopbarSearchForm.searchBtn" />
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobileOrIphone: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobileOrIphone: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(TopbarSearchFormComponent);
