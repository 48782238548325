import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import bnplImg from './bnpl.png';
import completedImg from './completed.png';
import logoImage from '../../assets/logo032024.png';
import imageWeb3 from '../../assets/web3Image.png';
import config from '../../config';
// import coins from '../../assets/coins.png';
import coins from '../../assets/webNewImage.webp';
import one from './imageOne.png';
import two from './newImage.png';

import css from './BNPL.module.css';
import border from '../../assets/border.png';
import web3 from '../../assets/web3.png';
const BNPLPage = () => {
  // prettier-ignore
  const mailLink = 'mailto:' + "info@automarketplace.com";
  return (
    <StaticPage
      title="Web3"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Web3',
        description: 'Web3',
        name: 'BNPLPage',
        image: web3,
      }}
      description={'automarketplace.com'}
      isStaticPage={true}
      facebookImages={[{ url: `${config.canonicalRootURL}${web3}`, width: 1200, height: 630 }]}
      twitterImages={[{ url: `${config.canonicalRootURL}${web3}`, width: 600, height: 314 }]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.sectionOne}>
            {/* <img src={logoImage} className={css.logo} alt="AutoMarketplace" /> */}

            <h1 className={css.sectionTitle}>Web3</h1>
            <p className={css.paragraph}>
              Web3 is an idea that the next iteration of online commerce will be defined by
              <p className={css.normal}> decentralization.</p>{' '}
              <i className={css.italy}>decentralization.</i> <br />
              <br />
              To be simple, Web3 basically means the economics of the internet will be more evenly
              distributed (less “middlemen”). One way this can be facilitated is by using blockchain
              technology.
            </p>
            <div className={css.heading}>
              <h2 className={css.subheading}>Web3 Vision</h2>
              <p className={css.paragraph2}>
                {' '}
                <i className={css.italyText}>(illustrative examples)</i>
              </p>
            </div>
            <ul className={css.list}>
              <li className={css.paragraphli}>
                Drivers <i>and</i> passengers own a stake in Uber or Lyft
              </li>
              <li className={css.paragraphli}>
                Guests <i>and</i> hosts own a stake in Airbnb
              </li>
              <li className={css.paragraphli}>
                Vendors <i>and</i> consumers own a stake in Amazon
              </li>
            </ul>
            {/* <p className={css.paragraph}>Let’s use an easy to understand illustrative example.</p> */}
          </div>
          <div className={css.marketSection}>
            <h2 className={css.marketHeader}>
              <span className={css.subheadingNew1}>
                Web3 marketplace rewards users who build network
              </span>
            </h2>
            <div className={css.images}>
              <img src={one} className={css.item} alt="AutoMarketplace" />
              <img src={two} className={css.item} alt="AutoMarketplace" />
            </div>
            <h2>
              <span className={css.subheadingNew2}>
                Web3 Marketplace Redefines“Middleman” Economics
              </span>
            </h2>
          </div>
          {/* <div className={css.sectionTwo}>
          <h1 className={css.sectionTitle}>Web3 Marketplace is User-Owned</h1>
          <h1 className={css.sectionTitle}>Web3 Marketplace Redefines“Middleman” Economics</h1>
          </div> */}
          <div className={css.sectionThree}>
            <h1 className={css.sectionTitle}>Blockchain Reward Tokens</h1>
            <p className={css.paragraph}>
              <b>AutoMarketplace Reward Tokens</b> will provide discounts and other benefits to our
              marketplace users. When you register, you’ll automatically be put on a waitlist.*
            </p>
            <div className={css.rewards}>
              <img src={coins} className={css.coins} alt="AutoMarketplace" />

              <p className={css.paragraph}>
                <b className={css.legalMobile}>*Legal Disclaimer</b>
                <b className={css.legalDesktop}>*Legal Disclaimer:</b> AutoMarketplace Reward Tokens
                should not be viewed as shares and/or providing an economic interest in any revenue
                or profits generated by any entity. Given evolving regulations around crypto and
                blockchain, it is important to note these tokens should not be viewed as shares in
                any company.{' '}
                <b style={{ color: '#0051ff' }}>AutoMarketplace tokens are reward points</b>
              </p>
            </div>

            <h1 className={css.sectionTitle}>Video</h1>
            <div className={css.videoContainer}>
              <p className={css.paragraph} style={{ textAlign: 'center' }}>
                <i>
                  <b>coming soon...</b>
                </i>
              </p>
            </div>

            {/* <div className={css.imageContainer}>
              <img src={logoImage} className={css.completed} alt="Completed" />
              <img src={coins} className={css.coins} alt="Coins" />
            </div> */}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default BNPLPage;
