import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { bool, func, shape } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { logout } from '../../ducks/Auth.duck';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { compose } from 'redux';
import { SiSubstack } from 'react-icons/si';
import { RiTwitterXLine } from 'react-icons/ri';
import { FaArrowCircleUp } from 'react-icons/fa';
import { FaArrowUp } from 'react-icons/fa';

import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  IconSocialMediaYoutube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import arrowUp from './arrowUp.png';
import { SiTiktok } from 'react-icons/si';
import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteLinkedinPage,
    siteYoutubePage,
    siteTikTokPage,
    siteSubStackPage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });
  const gotoTikTok = intl.formatMessage({ id: 'Footer.goToTikTok' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <RiTwitterXLine className={css.twitterIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  const subStackLink = siteSubStackPage ? (
    <ExternalLink
      key="linkToSubStack"
      href={siteSubStackPage}
      className={css.icon}
      title="SubStack"
    >
      <SiSubstack className={css.substack} />
    </ExternalLink>
  ) : null;
  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.icon}
      title={goToYoutube}
    >
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;
  const tiktokLink = siteTikTokPage ? (
    <ExternalLink key="linkToYoutube" href={siteTikTokPage} className={css.icon} title={gotoTikTok}>
      <SiTiktok className={css.tiktok} />
    </ExternalLink>
  ) : null;
  return [
    // linkedinLink,
    fbLink,
    twitterLink,
    subStackLink,

    // instragramLink,
    youtubeLink,
    // tiktokLink,
  ].filter(v => v != null);
};

const Footer = props => {
  const MAX_MOBILE_SCREEN_WIDTH = 768;
  const handleLogout = () => {
    const { onLogout, history } = props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }
    });
  };
  const { rootClassName, className, viewport, intl, isAuthenticated } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const state = useSelector(state => state);
  const { isDomainAutoMarketplace } = state.user;
  const isMobile = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const newListingLink = isAuthenticated ? (
    <li className={css.listItem}>
      {isDomainAutoMarketplace ? (
        <NamedLink name="NewListingPage" className={css.link}>
          <FormattedMessage id="Footer.toNewListingPage" />
        </NamedLink>
      ) : null}
      <span className={css.link} onClick={handleLogout}>
        <FormattedMessage id="Footer.signOutPage" />
      </span>
    </li>
  ) : (
    <li className={css.listItem}>
      <NamedLink name="LoginPage" className={css.link}>
        <FormattedMessage id="Footer.logInPage" />
      </NamedLink>
      <NamedLink name="SignupPage" className={css.link}>
        <FormattedMessage id="Footer.signUpPage" />
      </NamedLink>
    </li>
  );
  const variant = 'footer';
  const onScroll = () => {
    if (typeof window === 'object') window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          {isMobile ? (
            <div className={css.mobileContainer}>
              <Logo variant={variant} />
              <p className={css.footerContent}>
                <FormattedMessage
                  id="Footer.organizationDescription"
                  values={{
                    link: (
                      <NamedLink name="BNPLPage" className={css.linkText}>
                        <FormattedMessage id="Footer.organizationDescriptionLink" />
                      </NamedLink>
                    ),
                  }}
                />
                {/* <FormattedMessage id="Footer.mainContent" /> */}
              </p>
            </div>
          ) : null}
          <div className={css.someLiksMobile}>
            <div>{socialMediaLinks}</div>
            <NamedLink name="BNPLPage" className={css.web3}>
              Web3
            </NamedLink>
          </div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo variant={variant} />
                <NamedLink name="BNPLPage" className={css.web3Desktop}>
                  Web3
                </NamedLink>
              </NamedLink>

              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage
                    id="Footer.organizationDescription"
                    values={{
                      link: (
                        <NamedLink name="BNPLPage" className={css.linkText}>
                          <br />
                          <FormattedMessage id="Footer.organizationDescriptionLink" />
                        </NamedLink>
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                {newListingLink}
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  <NamedLink name="Service" className={css.link}>
                    Services
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="BNPLPage" className={css.link}>
                    Web3
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  <NamedLink name="ManageYourMaintenance" className={css.link}>
                    <FormattedMessage id="Footer.ManagingYourMaintenanceLink" />
                  </NamedLink>
                </li> */}
                {/* <li className={css.listItem}>
                  <NamedLink name="ElectricVehicle" className={css.link}>
                    <FormattedMessage id="Footer.EVs" />
                  </NamedLink>
                </li> */}
                {/* <li className={css.listItem}>
                  <NamedLink name="DataAndResearch" className={css.link}>
                    <FormattedMessage id="TopbarMobileMenu.DataAndResearch" />
                  </NamedLink>
                </li> */}
                <li className={css.listItem}>
                  <ExternalLink href="https://automarketplace.substack.com/" className={css.link}>
                    <FormattedMessage id="TopbarMobileMenu.newsletter" />
                  </ExternalLink>
                </li>
              </ul>
              <div className={css.yellowBorder} />
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                {/* {
    label: 'TLC Insurance',
    key: 'tlc_insurance',
    value: 'tlc_insurance',
  },
  {
    label: 'DMV Services',
    key: 'dmv_services',
    value: 'dmv_services',
  },
  {
    label: 'TLC Services',
    key: 'tlc_services',
    value: 'tlc_services',
  },
  {
    label: 'TLC Plate Rentals',
    key: 'tlc_plate_rentals',
    value: 'tlc_plate_rentals',
  },
  {
    label: 'TLC Car Rentals',
    key: 'tlc_car_rentals',
    value: 'tlc_car_rentals',
  },
  {
    label: 'Taxi Medallions',
    key: 'taxi_medallions',
    value: 'taxi_medallions',
  },
  {
    label: 'Taxi Garages',
    key: 'taxi_garages',
    value: 'taxi_garages',
  },
  {
    label: 'Black Car Bases',
    key: 'black_car_bases',
    value: 'black_car_bases',
  }, */}

                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=tlc_insurance',
                    }}
                    className={css.linkCategory}
                  >
                    TLC Insurance
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=dmv_services',
                    }}
                    className={css.linkCategory}
                  >
                    DMV Services
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=tlc_services',
                    }}
                    className={css.linkCategory}
                  >
                    TLC Services
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=tlc_plate_rentals',
                    }}
                    className={css.linkCategory}
                  >
                    TLC Plate Rentals
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=tlc_car_rentals',
                    }}
                    className={css.linkCategory}
                  >
                    TLC Car Rentals
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=taxi_medallions',
                    }}
                    className={css.linkCategory}
                  >
                    Taxi Medallions
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=taxi_garages',
                    }}
                    className={css.linkCategory}
                  >
                    Taxi Garages
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=black_car_bases',
                    }}
                    className={css.linkCategory}
                  >
                    Black Car Bases
                  </NamedLink>
                </li>
              </ul>
              <div className={css.copyrightAndTermsMobile}>
                <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
                <div className={css.tosAndPrivacyMobile}>
                  <NamedLink name="TermsOfServicePage" className={css.terms}>
                    <FormattedMessage id="Footer.terms" />
                  </NamedLink>
                  <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                    <FormattedMessage id="Footer.privacy" />
                  </NamedLink>
                </div>
              </div>
            </div>
            {isMobile ? (
              <div>
                <FaArrowUp className={css.arrowLink} onClick={onScroll} />

                {/* <img
                  src={arrowUp}
                  alt="Up Arrow Button"
                  className={css.arrowLink}
                  onClick={onScroll}
                /> */}
              </div>
            ) : null}
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <NamedLink name="LandingPage" className={css.organizationCopyrightEnd}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated,
  };
};
const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
});

export default compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(Footer);
