import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import NamedLink from '../NamedLink/NamedLink';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p>
        <b>Effective Date:</b> As of December 7, 2024
      </p>
      <p>
        Welcome to AutoMarketplace.com, the website and online service of Auto Marketplace LLC
        ("Company " "we, " or "us "). By accessing or using the AutoMarketplace service, website,
        related content (e.g., newsletter, YouTube channel, other social media) and software
        provided through or in connection with the service ("Service"), you signify that you have
        read, understood, and agree to be bound by this Privacy Policy, whether or not you are a
        registered user of our Service.
      </p>
      <p>
        This Privacy Policy explains how we collect, use, disclose, and protect your personal
        information when you access or use our website and services. By using AutoMarketplace.com,
        you agree to the practices described in this Privacy Policy.
      </p>
      <p>
        <b>
          Please read and refer to our{' '}
          <NamedLink name="TermsOfServicePage">Terms of Service</NamedLink> in conjunction with this
          Privacy Policy.
        </b>
      </p>
      <hr />
      <h3>1. Information We Collect</h3>
      <p>
        We collect information to provide a safe, efficient, and customized experience. This
        includes:
      </p>
      <h3>a. Information You Provide Directly</h3>
      <ul className={css.ul}>
        <li>
          <b>Account Information:</b> Name, email address, phone number, and password when you
          create an account.
        </li>
        <li>
          <b>Profile Details:</b> Additional information you add to your profile, such as address
          and preferences.
        </li>
        <li>
          <b>Listings:</b> Information about your business, related goods and services, including
          images and descriptions.
        </li>
        <li>
          <b>Payment Details:</b> Payment information processed by our third-party payment
          processors.
        </li>
      </ul>

      <h3>b. Information We Collect Automatically</h3>
      <hr />
      <ul className={css.ul}>
        <li>
          <b>Usage Data:</b> IP address, browser type, device information, and interactions with our
          website.
        </li>
        <li>
          <b>Cookies:</b> Data collected through cookies and similar technologies to enhance your
          experience.
        </li>
      </ul>
      <h3>c. Information from Third Parties</h3>
      <ul className={css.ul}>
        <li>
          <b>Social Media and Third-Party Accounts:</b> Information shared when you connect your
          social media or third-party accounts to AutoMarketplace.com.
        </li>
      </ul>
      <hr />
      <h3>2. How We Use Your Information</h3>
      <p>We use the information collected for the following purposes:</p>
      <ul className={css.ul}>
        <li>To provide and personalize our services.</li>
        <li>To process transactions and send payment confirmations.</li>
        <li>To communicate with you regarding your account, listings, and transactions.</li>
        <li>To improve our website and services through analytics and user feedback.</li>
        <li>To prevent fraud, unauthorized access, and other prohibited activities.</li>
        <li>To comply with legal obligations and enforce our terms of use.</li>
      </ul>
      <hr />
      <h3>3. Sharing Your Information</h3>
      <p>We may share your personal information in the following circumstances with:</p>
      <ul className={css.ul}>
        <li>
          <b>Vendors, who sometimes might be our advertising partners:</b> To facilitate interaction
          and transactions on and off the marketplace.
        </li>
        <li>
          <b>Service Providers:</b> With vendors who perform services on our behalf (e.g., payment
          processing, analytics).
        </li>
        <li>
          <b>Legal Compliance:</b> When required by law, court orders, or government requests.
        </li>
        <li>
          <b>Business Transfers:</b> In connection with a merger, sale, or acquisition of our
          business.
        </li>
      </ul>
      <p>
        We do not sell or rent your personal information to third parties for marketing purposes.
      </p>
      <hr />
      <h3>4. Your Choices</h3>
      <ul className={css.ul}>
        <li>
          <b>Account and Profile Settings:</b> Update your personal information and preferences in
          your account.
        </li>
        <li>
          <b>Marketing Communications:</b> Opt out of receiving promotional emails through the
          unsubscribe link in emails (or if unavailable or not functional) by emailing{' '}
          <a href="mailto:support@automarketplace.com">support@automarketplace.com</a>.
        </li>
        <li>
          <b>Cookies:</b> Adjust your browser settings to limit cookie use, though some features may
          be affected.
        </li>
      </ul>
      <hr />
      <h3>5. Data Security</h3>
      <p>
        We implement technical and organizational measures to protect your information. However, no
        online platform is 100% secure. We encourage you to take precautions, such as using strong
        passwords and avoiding sharing sensitive information.
      </p>
      <hr />
      <h3>6. Data Retention</h3>
      <p>
        We retain your personal information as long as necessary to provide services, comply with
        legal obligations, resolve disputes, and enforce our policies.
      </p>
      <hr />
      <h3>7. Children’s Privacy</h3>
      <p>
        AutoMarketplace.com is not intended for children under 13. We do not knowingly collect
        personal information from children.
      </p>
      <hr />
      <h3>8. International Users</h3>
      <p>
        By using AutoMarketplace.com, you consent to the transfer, processing, and storage of your
        information in the United States or other countries where we operate.
      </p>
      <hr />
      <h3>9. Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy periodically. Changes will be effective when posted.
        Please review this Privacy Policy regularly.
      </p>
      <hr />
      <h3>10. Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy or our data practices, please contact us
        at:
      </p>
      <p>
        <b>Email:</b> <a href="mailto:support@automarketplace.com">support@automarketplace.com </a>
      </p>
      <p>
        <b>Address:</b>
      </p>
      <p>
        AUTO MARKETPLACE LLC
        <br />
        REGISTERED AGENTS INC.
        <br />
        418 BROADWAY STE R, ALBANY, NY, UNITED STATES, 12207
      </p>
      <hr />
      <p>
        By continuing to use AutoMarketplace.com, you acknowledge that you have read and agree to
        this Privacy Policy.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
